import commonStore from '../common.store';
import RESTService from '../rest.service';
import { Station } from './station.interface';

const expand = ['user'].join(',');

export class StationService extends RESTService<Station> {
  list(params?: { [key: string]: any }) {
    return super.list({ expand, ...commonStore.userAssociatedInfo, ...params });
  }
  get(id: string, params?: { [key: string]: any }) {
    return super.get(id, { expand, ...params });
  }
}

const stationService = new StationService('/admin/station');

export default stationService;
