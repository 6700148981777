import { Status } from '../common.interface';
import RESTService from '../rest.service';
import request from '../utils/request';
import { Plan } from './plan.interface';

export class PlanService extends RESTService<Plan> {
  // list(params?: { [key: string]: any }) {
  //   return super.list({ ...commonStore.userAssociatedInfo, ...params });
  // }
  changeStatus(params: { planId: string; status: keyof typeof Status }) {
    return request(`${this.service}/change-status`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }
  activate(params: { planId: string }) {
    return request(`${this.service}/activate`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }
  addSchool(params: { planId: string; schoolIdList: string[] }) {
    return request(`${this.service}/add-school`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }
  removeSchool(params: { planId: string; schoolIdList: string[] }) {
    return request(`${this.service}/remove-school`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }
  changeArea(id: string, params: any) {
    return request(`${this.service}/area/${id}`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'PATCH',
    });
  }
}

const planService = new PlanService('/admin/plan');

export default planService;
