import { PaginationMeta } from '../common.interface';
import RESTService from '../rest.service';
import { Check } from '../check/check.interface';
import request from '../utils/request';
import { stringify } from 'querystring';

export class BasicdataService extends RESTService<Check> {
  list(params?: { [key: string]: any }) {
    return super.list({
      ...params,
      expand: [
        'school',
        ...(params?.expand ?? []),
      ]
        .filter(Boolean)
        .join(','),
    })
    .then(({ items, meta }) => ({
      items: items.map((item) => ({
        ...item,
        ...item.data,
        school: item.school,
      })),
      meta,
    })) as Promise<{
      items: Check[];
      meta: PaginationMeta;
    }>;
  }
  getCureReport(params?:{[key: string]: any } ){
    return request(`${this.service}/cure-report?${stringify(params)}`);
  }


}

const basicdataService = new BasicdataService('/admin/revisit');

export default basicdataService;
