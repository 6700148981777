import { stringify } from 'qs';
import RESTService from '../rest.service';
import request from '../utils/request';
import { DataReport } from './datareport.interface';

export class DataReportService extends RESTService<DataReport> {
  // 查询上报任务列表
  reportlist(params: any) {
    return request(`${this.service}/up-report/up?${stringify(params)}`)
  }
  // 查询接收列表
  receivelist(params: any) {
    return request(`${this.service}/up-report/lower?${stringify(params)}`)
  }
  // 上报
  report(params: {
    planId: string;
    zone: string;
    type: string;
  }) {
    return request(`${this.service}/up-report`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }
  // 状态修改(撤销,审核通过.退回)
  statusUpdate(params: { 
    id: string; 
    status: string;
    remark?: string;
    rejectReason?: string;
  }) {
    return request(`${this.service}/up-report/change`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'PATCH',
    });
  }
}

const datareportService = new DataReportService('/admin/report');

export default datareportService;
