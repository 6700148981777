import { action, computed, observable, set } from 'mobx';
import { task } from 'mobx-task';
import { normalize, schema } from 'normalizr';
import { stringify } from 'qs';
import { defaultPagination } from '../config';
import { Check } from '../check/check.interface';
import patientService from './patient.service';

export const patientSchema = new schema.Entity('patient');

export class PatientStore {
  @observable query?: { [key: string]: any };
  @observable entities: { [key: string]: Check } = {};
  @observable ids: { [qs: string]: number[] } = {};
  @observable pgs: { [qs: string]: { [key: string]: number } } = {};

  @computed get dataSource() {
    return (this.ids[stringify(this.query, { sort: (a, b) => a.localeCompare(b) })] || []).map(
      (id) => this.entities[id],
    );
  }

  @computed get pagination() {
    const pagination = this.pgs[stringify(this.query, { sort: (a, b) => a.localeCompare(b) })];
    return (
      !!pagination && {
        current: pagination.page + 1,
        pageSize: pagination.size,
        total: pagination.total,
        ...defaultPagination,
      }
    );
  }

  @action updateEntities(entities: { [key: string]: Check } = {}) {
    const ids = Object.keys(entities);
    for (const id of ids) {
      this.entities[id] = { ...this.entities[id], ...entities[id] };
    }
  }

  @action relist(query: Parameters<typeof patientService.list>[0], reload = false) {
    return this.list({ ...this.query, ...query }, reload);
  }

  @task.resolved @action async list(query: Parameters<typeof patientService.list>[0], reload = false) {
    this.query = query;
    const qs = stringify(this.query, { sort: (a, b) => a.localeCompare(b) });

    // 如果列表已加载且不刷新，则使用缓存
    if (this.ids[qs] && !reload) {
      return {
        items: this.ids[qs].map((id) => this.entities[id]),
        meta: this.pgs[qs],
      };
    }

    const data = await patientService.list(this.query);
    const { result, entities } = normalize(data.items, [patientSchema]);
    this.updateEntities(entities.patient);
    set(this, {
      ids: reload ? { [qs]: result } : { ...this.ids, [qs]: result },
      pgs: reload ? { [qs]: data.meta } : { ...this.pgs, [qs]: data.meta },
    });

    return data;
  }

  @task.resolved @action async get(...args: Parameters<typeof patientService.get>) {
    const data = await patientService.get(...args);
    const { entities } = normalize(data, patientSchema);
    this.updateEntities(entities.patient);
    return data;
  }
  @task.resolved @action async create(...args: Parameters<typeof patientService.create>) {
    const data = await patientService.create(...args);
    const { entities } = normalize(data, patientSchema);
    this.updateEntities(entities.patient);
    return data;
  }
  @task.resolved @action async update(...args: Parameters<typeof patientService.update>) {
    const data = await patientService.update(...args);
    const { entities } = normalize(data, patientSchema);
    this.updateEntities(entities.patient);
    return data;
  }

  @task.resolved @action remove = patientService.remove.bind(patientService);
}

const patientStore = new PatientStore();

export default patientStore;
