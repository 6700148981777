import { action, computed, observable, set } from 'mobx';
import { task } from 'mobx-task';
import { normalize, schema } from 'normalizr';
import { stringify } from 'qs';
import { defaultPagination } from '../config';
import { School } from './school.interface';
import schoolService from './school.service';

export const schoolSchema = new schema.Entity('school');

export class SchoolStore {
  @observable query?: { [key: string]: string };
  @observable entities: { [key: string]: School } = {};
  @observable ids: { [qs: string]: number[] } = {};
  @observable pgs: { [qs: string]: { [key: string]: number } } = {};

  @computed get dataSource() {
    return (this.ids[stringify(this.query, { sort: (a, b) => a.localeCompare(b) })] || []).map(
      (id) => this.entities[id],
    );
  }

  @computed get pagination() {
    const pagination = this.pgs[stringify(this.query, { sort: (a, b) => a.localeCompare(b) })];
    return (
      !!pagination && {
        current: pagination.page + 1,
        pageSize: pagination.size,
        total: pagination.total,
        ...defaultPagination,
      }
    );
  }

  @action updateEntities(entities: { [key: string]: School } = {}) {
    const ids = Object.keys(entities);
    for (const id of ids) {
      this.entities = { ...this.entities, [id]: { ...this.entities[id], ...entities[id] } }; // mobx4
      // this.entities[id] = { ...this.entities[id], ...entities[id] }; // mobx5
    }
  }

  @action relist(query?: Parameters<typeof schoolService.list>[0], reload = false) {
    return this.list({ ...this.query, ...query }, reload);
  }

  @task.resolved @action async list(query?: Parameters<typeof schoolService.list>[0], reload = false) {
    this.query = query;
    const qs = stringify(this.query, { sort: (a, b) => a.localeCompare(b) });

    // 如果列表已加载且不刷新，则使用缓存
    if (this.ids[qs] && !reload) {
      return {
        items: this.ids[qs].map((id) => this.entities[id]),
        meta: this.pgs[qs],
      };
    }

    const data = await schoolService.list(this.query);
    const { result, entities } = normalize(data.items, [schoolSchema]);
    this.updateEntities(entities.school);
    set(this, {
      ids: reload ? { [qs]: result } : { ...this.ids, [qs]: result },
      pgs: reload ? { [qs]: data.meta } : { ...this.pgs, [qs]: data.meta },
    });

    return data;
  }

  @task.resolved @action async get(...args: Parameters<typeof schoolService.get>) {
    const data = await schoolService.get(...args);
    const { entities } = normalize(data, schoolSchema);
    this.updateEntities(entities.school);
    return data;
  }
  @task.resolved @action async create(...args: Parameters<typeof schoolService.create>) {
    const data = await schoolService.create(...args);
    const { entities } = normalize(data, schoolSchema);
    this.updateEntities(entities.school);
    return data;
  }
  @task.resolved @action async update(...args: Parameters<typeof schoolService.update>) {
    const data = await schoolService.update(...args);
    const { entities } = normalize(data, schoolSchema);
    this.updateEntities(entities.school);
    return data;
  }
  @task.resolved @action remove = schoolService.remove.bind(schoolService);
}

const schoolStore = new SchoolStore();

export default schoolStore;
