import RESTService from '../rest.service';
import { Inhabitant } from './inhabitant.interface';

export class InhabitantService extends RESTService<Inhabitant> {
  list(params?: { [key: string]: any }) {
    const expand = ['community', 'record', 'station', 'revisit'].filter((str) => !!str).join(',');
    return super.list({ expand, ...params });
  }
}

const inhabitantService = new InhabitantService('/admin/resident');

export default inhabitantService;
