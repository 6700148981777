import { Router } from '@reach/router';
import { inject, observer } from 'mobx-react';
import { parse } from 'qs';
import React, { Suspense } from 'react';
import { CommonStore } from './common.store';
import SuspenseLoading from './components/SuspenseLoading';

const AuthLayout = React.lazy(() => import('./layouts/AuthLayout'));
const BasicLayout = React.lazy(() => import('./layouts/BasicLayout'));
const MobileLayout = React.lazy(() => import('./layouts/MobileLayout'));
const ReportDashboard = React.lazy(() => import('./report/Dashboard'));

interface AppProps {
  common?: CommonStore;
}

@inject('common')
@observer
class App extends React.Component<AppProps> {
  render() {
    const { common } = this.props;
    const query = parse(window.location.search.slice(1));
    const isWeixin = !!~window.navigator.userAgent.toLowerCase().indexOf('micromessenger') || !!query?.wx;
    const isQuestionnaire = !!query?.questionnaire;

    return (
      <Suspense fallback={<SuspenseLoading />}>
        <Router>
          {(isWeixin || isQuestionnaire) && <MobileLayout path="/*" />}

          {common!.authed ? (
            [<ReportDashboard path="dashboard" title="可视化云图" key="1" />, <BasicLayout path="/*" key="2" />]
          ) : (
            <AuthLayout path="/*" />
          )}
        </Router>
      </Suspense>
    );
  }
}

export default App;
