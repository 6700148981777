import { action, computed, observable, set } from 'mobx';
import { task } from 'mobx-task';
import { normalize, schema } from 'normalizr';
import { stringify } from 'qs';
import { PaginationMeta } from '../common.interface';
import { defaultPagination } from '../config';
import reportRecheckSampleService from '../sample_record/sample_report_recheck.service';
import { ReportRecheck } from './report.interface';
import reportRecheckService from './report.recheck.service';

export const reportRecheckSchema = new schema.Entity('reportRecheck');

export class ReportRecheckStore {
  @observable mode?: 'sample';
  @observable query?: Parameters<typeof reportRecheckService.list>[0];
  @observable entities: { [key: string]: ReportRecheck } = {};
  @observable ids: { [qs: string]: number[] } = {};
  @observable pgs: { [qs: string]: { [key: string]: number } } = {};

  @computed get dataSource() {
    return (this.ids[stringify(this.query, { sort: (a, b) => a.localeCompare(b) })] || []).map(
      (id) => this.entities[id],
    );
  }

  @computed get pagination() {
    const pagination = this.pgs[stringify(this.query, { sort: (a, b) => a.localeCompare(b) })];
    return (
      !!pagination && {
        current: pagination.page + 1,
        pageSize: pagination.size,
        total: pagination.total,
        ...defaultPagination,
      }
    );
  }

  getDataSource(query: { [key: string]: any }) {
    return (this.ids[stringify(query, { sort: (a, b) => a.localeCompare(b) })] || []).map((id) => this.entities[id]);
  }

  getPagination(query: { [key: string]: any }) {
    const pagination = this.pgs[stringify(query, { sort: (a, b) => a.localeCompare(b) })];
    return (
      !!pagination && {
        current: pagination.page + 1,
        pageSize: pagination.size,
        total: pagination.total,
        ...defaultPagination,
      }
    );
  }

  @action updateEntities(entities: { [key: string]: ReportRecheck } = {}) {
    const ids = Object.keys(entities);
    for (const id of ids) {
      this.entities = { ...this.entities, [id]: { ...this.entities[id], ...entities[id] } }; // mobx4
      // this.entities[id] = { ...this.entities[id], ...entities[id] }; // mobx5
    }
  }

  @action relist(query: Partial<Parameters<typeof reportRecheckService.list>[0]>, reload = false) {
    return this.list({ ...this.query, ...query } as any, reload);
  }

  @task.resolved @action async list(query: Parameters<typeof reportRecheckService.list>[0], reload = false) {
    this.query = { ...query, mode: this.mode };
    const qs = stringify(this.query, { sort: (a, b) => a.localeCompare(b) });

    // 如果列表已加载且不刷新，则使用缓存
    if (this.ids[qs] && !reload) {
      return this.ids[qs].map((id) => this.entities[id]);
    }

    const listMethod =
      ({
        sample: reportRecheckSampleService.list.bind(reportRecheckSampleService),
      } as any)[this.query?.mode!] || reportRecheckService.list.bind(reportRecheckService);

    const data = (await listMethod(this.query)) as {
      items: ReportRecheck[];
      meta: PaginationMeta;
    };

    if (data?.items) {
      // ! data 可能为 null
      const { result, entities } = normalize(data.items, [reportRecheckSchema]);
      this.updateEntities(entities.reportRecheck);
      set(this, {
        ids: reload ? { [qs]: result } : { ...this.ids, [qs]: result },
        pgs: reload ? { [qs]: data.meta } : { ...this.pgs, [qs]: data.meta },
      });
    }

    return data;
  }

  @task.resolved @action async get(...args: Parameters<typeof reportRecheckService.get>) {
    const data = await reportRecheckService.get(...args);
    if (data) {
      // ! data 可能为 null
      const { entities } = normalize(data, reportRecheckSchema);
      this.updateEntities(entities.reportRecheck);
    }
    return data;
  }
}

const reportRecheckStore = new ReportRecheckStore();

export default reportRecheckStore;
