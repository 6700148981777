import { find } from 'lodash';
import { stringify } from 'qs';
import BaseService from './base.service';
import request, { requestBlob } from './utils/request';

export class CommonService extends BaseService {
  listProvince(): Promise<{ province: string; code: string }[]> {
    return request(`${window.CONFIG.commonServiceEndpoint}/area/v1/province`);
  }
  listCity(params: { province: string }): Promise<{ city: string; code: string; provinceCode: string }[]> {
    return request(`${window.CONFIG.commonServiceEndpoint}/area/v1/city?${stringify(params)}`);
  }
  listCounty(params: { province: string; city: string; county?: string }): Promise<{ county: string; code: string; cityCode: string }[]> {
    return request(`${window.CONFIG.commonServiceEndpoint}/area/v1/county?${stringify(params)}`);
  }
  // uploadToOSS(params: { file: string; objectName: string }) {
  //   return request('/admin/oss/upload', {
  //     body: JSON.stringify(params),
  //     headers: { 'Content-Type': 'application/json' },
  //     method: 'post',
  //   });
  // }
  // 修改成按这样上传
  uploadToOSS(params: FormData) {  //{ file: File; objectName: string }
    return request('/admin/oss/upload', {
      // headers: { 'Content-Type': 'multipart/form-data' },   //因为上传FormData时，任然有其他参数要生成，所以上传的时候就不添加请求头
      method: 'post',
      body: params
    });
  }
  // getOSSUrl(objectName: string) {
  //   return request(`/admin/oss/download?${stringify({ objectName })}`);
  // }
  getOSSUrl(objectName: string) {
    return `${window.location.origin}/api/admin/oss/download-file?${stringify({ objectName })}`;
  }
  shortUrl(params: { url: any }) {
    return request(`${window.CONFIG.commonServiceEndpoint}/url/v1/shorten`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }
  // useShorUrl(id: string) {
  //   return request(`${window.CONFIG.commonServiceEndpoint}/${id}`);
  // }

  async getAreaCode(province: string, city?: string, county?: string): Promise<string[]> {
    const codeList = [];
    if (province) {
      const provinceList = await this.listProvince();
      codeList.push(find(provinceList, { province })!.code.substr(0, 2));
    }
    if (city) {
      const cityList = await this.listCity({ province });
      codeList.push(find(cityList, { city })!.code.substr(2, 2));
    }
    if (county) {
      const countyList = await this.listCounty({ province, city: city! });
      codeList.push(find(countyList, { county })!.code.substr(4, 2));
    }
    return codeList;
  }

  generateExcel(params: { fileName?: string; title?: any; titleFormat?: any; data?:any; cellToMerge?:any;}) {
    return requestBlob(`${window.CONFIG.commonServiceEndpoint}/excel/v1/generate`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }
}

const commonService = new CommonService('');

export default commonService;
