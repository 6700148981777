export const defaultPagination = {
  // hideOnSinglePage: true,
  showSizeChanger: true,
  pageSizeOptions: ['10', '20', '50', '100'],
  showTotal: (total: number) => `共 ${total} 条`,
};

export const CDN_HOST = 'https://p.qpaimg.com';
export const API_COMMON_HOST = 'https://commservice.qingpai365.com';
export const API_QPSC_HOST = window.origin || 'https://govdemo.qpsc365.com';
export const API_CHART_HOST = 'https://myopiacalc.qingpai365.com';