import { action, computed, observable, set } from 'mobx';
import { task } from 'mobx-task';
import { normalize, schema } from 'normalizr';
import { stringify } from 'qs';
import { defaultPagination } from '../config';
import { SmsGroup } from './group.interface' ;
import groupService from './group.service';
import smsConfigService from './smsConfig.service';

export const groupSchema = new schema.Entity('group');

export class GroupStore {
  @observable query?: { [key: string]: any };
  @observable entities: { [key: string]: SmsGroup } = {};
  @observable ids: { [qs: string]: number[] } = {};
  @observable pgs: { [qs: string]: { [key: string]: number } } = {};

  @computed get dataSource() {
    return (this.ids[stringify(this.query, { sort: (a, b) => a.localeCompare(b) })] || []).map(
      (id) => this.entities[id],
    );
  }

  @computed get pagination() {
    const pagination = this.pgs[stringify(this.query, { sort: (a, b) => a.localeCompare(b) })];
    return (
      !!pagination && {
        current: pagination.page + 1,
        pageSize: pagination.size,
        total: pagination.total,
        ...defaultPagination,
      }
    );
  }

  @action updateEntities(entities: { [key: string]: SmsGroup } = {}) {
    const ids = Object.keys(entities);
    for (const id of ids) {
      this.entities = { ...this.entities, [id]: { ...this.entities[id], ...entities[id] } }; // mobx4
      // this.entities[id] = { ...this.entities[id], ...entities[id] }; // mobx5
    }
  }

  @action relist(query: Parameters<typeof groupService.list>[0], reload = false) {
    return this.list({ ...this.query, ...query }, reload);
  }

  @task.resolved @action async list(query: Parameters<typeof groupService.list>[0], reload = false) {
    this.query = query;
    const qs = stringify(this.query, { sort: (a, b) => a.localeCompare(b) });

    // 如果列表已加载且不刷新，则使用缓存
    if (this.ids[qs] && !reload) {
      return {
        items: this.ids[qs].map((id) => this.entities[id]),
        meta: this.pgs[qs],
      };
    }

    const data = await groupService.list(this.query);
    const { result, entities } = normalize(data.items, [groupSchema]);
    this.updateEntities(entities.group);
    set(this, {
      ids: reload ? { [qs]: result } : { ...this.ids, [qs]: result },
      pgs: reload ? { [qs]: data.meta } : { ...this.pgs, [qs]: data.meta },
    });

    return data;
  }

  @task.resolved @action async get(...args: Parameters<typeof groupService.get>) {
    const data = await groupService.get(...args);
    const { entities } = normalize(data, groupSchema);
    this.updateEntities(entities.group);
    return data;
  }
  @task.resolved @action async create(...args: Parameters<typeof groupService.create>) {
    const data = await groupService.create(...args);
    const { entities } = normalize(data, groupSchema);
    this.updateEntities(entities.group);
    return data;
  }
  @task.resolved @action async update(...args: Parameters<typeof groupService.update>) {
    const data = await groupService.update(...args);
    const { entities } = normalize(data, groupSchema);
    this.updateEntities(entities.group);
    return data;
  }
  
  @task.resolved @action remove = groupService.remove.bind(groupService);
  @task.resolved @action batchList = groupService.batchList.bind(groupService);
  @task.resolved @action batchSend = groupService.batchSend.bind(groupService);
  @task.resolved @action customer = groupService.customer.bind(groupService);
  @task.resolved @action getClient = smsConfigService.getClient.bind(smsConfigService);
  @task.resolved @action createConfig = smsConfigService.createConfig.bind(smsConfigService);
  @task.resolved @action updateAreaConfig = smsConfigService.updateAreaConfig.bind(smsConfigService);
}

const groupStore = new GroupStore();

export default groupStore;
