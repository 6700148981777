import { action, computed, observable, set } from 'mobx';
import { task } from 'mobx-task';
import authService from './auth/auth.service';
import commonService from './common.service';
import { Plan } from './plan/plan.interface';
import { School } from './school/school.interface';
import { Station } from './station/station.interface';
import { User } from './user/user.interface';
import userService from './user/user.service';
import { localStorage, sessionStorage } from './utils/storage';

export class CommonStore {
  @observable authed: boolean = !!sessionStorage.get('token');
  @observable plan?: Plan | undefined = localStorage.get('plan');
  @observable user?: User | undefined;
  @observable station?: Station | undefined;
  @observable school?: School | undefined;

  @observable screen: { width: number; height: number } = {
    width: document.body.clientWidth,
    height: document.body.clientHeight,
  };
  @observable domain: string = window.location.hostname.split('.')[0]; // 当前域名

  @computed get userAssociatedInfo() {
    return {
      province: this.user?.province || undefined,
      city: this.user?.city || undefined,
      county: this.user?.county || undefined,
      stationId: this.user?.stationId || undefined,
      schoolId: this.user?.schoolId || undefined,
      grade: this.user?.grade || undefined,
      clazz: this.user?.clazz || undefined,
    };
  }

  @task.resolved @action login = async (formData: any) => {
    const data = await authService.login(formData);
    sessionStorage.set('token', data.token);

    await this.init(); // 登录后进行初始化，初始化完成后再设置 authed
    this.authed = true;

    return data;
  };

  @task.resolved @action loginByToken = async (token: string) => {
    sessionStorage.set('token', token);
    await this.init(); // 登录后进行初始化，初始化完成后再设置 authed
    this.authed = true;
  };

  @task @action init = async () => {
    const user = await userService.getCurrentUser();
    const [plan, station, school] = await Promise.all([
      ~['Station', 'School', 'RevisitDoctor'].indexOf(user?.group!) //深圳星光 复诊医生也要返回数据plan数据
        ? userService.getCurrentPlan()
        : Promise.resolve(localStorage.get('plan')),
      ~['Station', 'School', 'RevisitDoctor'].indexOf(user?.group!) ? userService.getCurrentStation() : Promise.resolve(undefined),
      ~['School'].indexOf(user?.group!) ? userService.getCurrentSchool() : Promise.resolve(undefined),
    ]);
    set(this, { user, station, plan, school });
  };

  @action logout = () => {
    sessionStorage.clearAll();
    localStorage.clearAll();
    window.location.replace(window.location.origin);
  };

  @action setPlanAsCurrent(plan?: Plan) {
    this.plan = plan;
    localStorage.set('plan', plan);
  }
  
  @task.resolved @action async uploadFile(...args: Parameters<typeof commonService.uploadToOSS>) {
    const data = await commonService.uploadToOSS(...args);
    return data;
  }
}

const commonStore = new CommonStore();

export default commonStore;

window.onresize = () => {
  commonStore.screen = {
    width: document.body.clientWidth,
    height: document.body.clientHeight,
  };
};
