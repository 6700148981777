import { stringify } from 'qs';
import BaseService from '../base.service';
import { PaginationMeta } from '../common.interface';
import commonStore from '../common.store';
import { ReportSchool } from '../report/report.interface';
import { SchoolCategory } from '../school/school.interface';
import request from '../utils/request';

export class ReportService extends BaseService {
  // 按省市区检测点、学校类型、学校、年级、班级查看列表
  list(params: {
    planId: string;
    province?: string;
    city?: string;
    county?: string;
    stationId?: string;
    schoolCategory?: keyof typeof SchoolCategory | 'All';
    schoolId?: string;
    grade?: string;
    clazz?: string;
    age?: boolean;
    group?: string;
    [key: string]: any;
  }): Promise<ReportSchool[] | { items: ReportSchool[]; meta: PaginationMeta }> {
    const { planId, province, city, county, stationId, schoolCategory, schoolId, grade, clazz, age, ...rest } = {
      ...commonStore.userAssociatedInfo,
      ...params,
    };
    if (!planId) return Promise.reject('任务ID是必须的');
    if (age) {
      return this.reportAgeList({
        planId,
        province,
        city,
        county,
        stationId,
        schoolCategory: schoolCategory as keyof typeof SchoolCategory,
        schoolId,
        grade,
        clazz,
        ...rest,
      });
    }

    if (schoolCategory === 'All') {
      if (stationId) {
        return this.reportSchoolCategoryList({ planId, stationId, ...rest });
      }
      if (county && city) {
        return this.reportSchoolCategoryList({ planId, city, county, ...rest });
      }
      if (city) {
        return this.reportSchoolCategoryList({ planId, city, ...rest });
      }
      if (province) {
        return this.reportSchoolCategoryList({ planId, province, ...rest });
      }
    }

    if (grade && schoolId) {
      return this.reportList({ planId, schoolId, grade, group: 'clazz', ...rest });
    }
    if (schoolId) {
      return this.reportList({ planId, schoolId, group: 'grade', ...rest });
    }
    if (stationId) {
      return this.reportList({ planId, stationId, group: 'school', ...rest });
    }
    if (county && city) {
      return this.reportList({ planId, city, county, group: 'station', ...rest });
    }
    if (city) {
      return this.reportList({ planId, city, group: 'county', ...rest });
    }
    if (province) {
      return this.reportList({ planId, province, group: 'city', ...rest });
    }

    return Promise.reject('参数不正确');
  }

  // 按省市区检测点、学校类型、学校、年级、班级查看
  get(params: {
    planId: string;
    province?: string;
    city?: string;
    county?: string;
    stationId?: string;
    schoolId?: string;
    grade?: string;
    clazz?: string;
    [key: string]: any;
  }): Promise<ReportSchool> {
    const { planId, province, city, county, stationId, schoolId, grade, clazz, ...rest } = {
      ...commonStore.userAssociatedInfo,
      ...params,
    };
    if (!planId) return Promise.reject('任务ID是必须的');
    if (clazz && grade && schoolId) {
      return this.reportList({ planId, schoolId, grade, clazz, ...rest });
    }
    if (grade && schoolId) {
      return this.reportList({ planId, schoolId, grade, ...rest });
    }
    if (schoolId) {
      return this.reportList({ planId, schoolId, ...rest });
    }
    if (stationId) {
      return this.reportList({ planId, stationId, ...rest });
    }
    if (county && city) {
      return this.reportList({ planId, city, county, ...rest });
    }
    if (city) {
      return this.reportList({ planId, city, ...rest });
    }
    if (province) {
      return this.reportList({ planId, province });
    }
    return Promise.reject('参数不正确');
  }

  // 上报数据统计
  reportList(params: { planId: string; [key: string]: any }) {
    return request(`${this.service}/updata-report?${stringify(params)}`);
  }

  // 上报数据按学校类型统计
  reportSchoolCategoryList(params: { planId: string; [key: string]: any }) {
    return request(`${this.service}/updata-report/schoolCategory?${stringify(params)}`);
  }

  // 上报数据按年龄统计
  reportAgeList(params: { planId: string; [key: string]: any }) {
    return request(`${this.service}/updata-report/age?${stringify(params)}`);
  }
}

const reportService = new ReportService('/admin/report');

export default reportService;
