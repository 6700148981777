import { stringify } from 'qs';
import BaseService from './base.service';
import { PaginationMeta } from './common.interface';
import request from './utils/request';

export default class RESTService<T> extends BaseService {
  list(params?: { [key: string]: any }): Promise<{ items: T[]; meta: PaginationMeta }> {
    return request(`${this.service}?${stringify({ size: 10, ...params }, { skipNulls: true })}`);
  }

  get(id: number | string, params?: { [key: string]: any }): Promise<T> {
    return request(`${this.service}/${id}?${stringify(params, { skipNulls: true })}`);
  }

  async create(params: Partial<T>): Promise<T> {
    return request(`${this.service}`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }

  async update(id: number | string, params: Partial<T>): Promise<T> {
    return request(`${this.service}/${id}`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'PATCH',
    });
  }

  remove(ids: number | string, params?: any) {
    return request(`${this.service}/${ids}?${stringify(params, { skipNulls: true })}`, {
      method: 'delete',
    });
  }
}
