import { Check } from '../check/check.interface';
import { PaginationMeta } from '../common.interface';
import { Recheck } from '../recheck/recheck.interface';
import RESTService from '../rest.service';
import { SampleRecord } from './sample_record.interface';
import request from '../utils/request';

export class SampleRecordService extends RESTService<SampleRecord> {
  listByType(
    type: 'check' | 'recheck',
    params?: { [key: string]: any },
  ): Promise<{ items: (Check | Recheck)[]; meta: PaginationMeta }> {
    if (type === 'check' && !params?.planId)
      return Promise.resolve({ items: [], meta: { page: 0, pages: 0, size: 0, total: 0 } });
    if (type === 'recheck' && (!params?.planId || !params?.schoolId || !params?.title)) {
      return Promise.resolve({ items: [], meta: { page: 0, pages: 0, size: 0, total: 0 } });
    }

    switch (type) {
      case 'check':
        return super
          .list({
            expand: [
              'station',
              'school',
              'fullId',
              'record',
              'student',
              params?.export && window.CONFIG.checkExportClearIdcard && 'export',
            ]
              .filter(Boolean)
              .join(','),
            ...params,
          })
          .then((data) => ({
            ...data,
            items: data.items.filter((item) => item.record).map((item) => ({ ...item.record!, id: item.id })),
          }));
      case 'recheck':
        return super
          .list({
            expand: [
              'station',
              'school',
              'fullId',
              'record',
              'student',
              'retest',
              params?.export && window.CONFIG.checkExportClearIdcard && 'export',
            ]
              .filter(Boolean)
              .join(','),
            ...params,
          })
          .then((data) => ({
            ...data,
            items: data.items
              .filter((item) => item.retest)
              .map((item) => ({ ...item.retest!, record: item.record, id: item.id })),
          }));
    }
  };
  delete(params: { [key: string]: any }) {
    return request(`${this.service}/delete`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }
}

const sampleRecordService = new SampleRecordService('/admin/sample-record');

export default sampleRecordService;
