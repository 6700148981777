import { action, computed, observable, set } from 'mobx';
import { task } from 'mobx-task';
import { normalize, schema } from 'normalizr';
import { stringify } from 'qs';
import checkService from '../check/check.service';
import { defaultPagination } from '../config';
import schoolStore, { schoolSchema } from '../school/school.store';
import { Plan } from './plan.interface';
import planService from './plan.service';

export const planSchema = new schema.Entity('plan');

export class PlanStore {
  @observable query?: { [key: string]: any };
  @observable entities: { [key: string]: Plan } = {};
  @observable ids: { [qs: string]: number[] } = {};
  @observable pgs: { [qs: string]: { [key: string]: number } } = {};

  @computed get dataSource() {
    return (this.ids[stringify(this.query, { sort: (a, b) => a.localeCompare(b) })] || []).map(
      (id) => this.entities[id],
    );
  }

  @computed get pagination() {
    const pagination = this.pgs[stringify(this.query, { sort: (a, b) => a.localeCompare(b) })];
    return (
      !!pagination && {
        current: pagination.page + 1,
        pageSize: pagination.size,
        total: pagination.total,
        ...defaultPagination,
      }
    );
  }

  @action updateEntities(entities: { [key: string]: Plan } = {}) {
    const ids = Object.keys(entities);
    for (const id of ids) {
      this.entities = { ...this.entities, [id]: { ...this.entities[id], ...entities[id] } }; // mobx4
      // this.entities[id] = { ...this.entities[id], ...entities[id] }; // mobx5
    }
  }

  @action relist(query: Parameters<typeof planService.list>[0], reload = false) {
    return this.list({ ...this.query, ...query }, reload);
  }

  @task.resolved @action async list(query: Parameters<typeof planService.list>[0], reload = false) {
    this.query = query;
    const qs = stringify(this.query, { sort: (a, b) => a.localeCompare(b) });

    // 如果列表已加载且不刷新，则使用缓存
    if (this.ids[qs] && !reload) {
      return {
        items: this.ids[qs].map((id) => this.entities[id]),
        meta: this.pgs[qs],
      };
    }

    const data = await planService.list(this.query);
    const { result, entities } = normalize(data.items, [planSchema]);
    this.updateEntities(entities.plan);
    set(this, {
      ids: reload ? { [qs]: result } : { ...this.ids, [qs]: result },
      pgs: reload ? { [qs]: data.meta } : { ...this.pgs, [qs]: data.meta },
    });

    return data;
  }

  @task.resolved @action async get(...args: Parameters<typeof planService.get>) {
    const data = await planService.get(...args);
    const { entities } = normalize(data, planSchema);
    schoolStore.updateEntities(normalize(data.schoolList || [], [schoolSchema]).entities.school);
    this.updateEntities(entities.plan);
    return data;
  }
  @task.resolved @action async create(...args: Parameters<typeof planService.create>) {
    const data = await planService.create(...args);
    const { entities } = normalize(data, planSchema);
    this.updateEntities(entities.plan);
    return data;
  }
  @task.resolved @action async update(...args: Parameters<typeof planService.update>) {
    const data = await planService.update(...args);
    const { entities } = normalize(data, planSchema);
    this.updateEntities(entities.plan);
    return data;
  }
  @task.resolved @action remove = planService.remove.bind(planService);
  @task.resolved @action activate = planService.activate.bind(planService);
  @task.resolved @action changeStatus = planService.changeStatus.bind(planService);
  @task.resolved @action import = checkService.import.bind(checkService);
  @task.resolved @action addSchool = planService.addSchool.bind(planService);
  @task.resolved @action removeSchool = planService.removeSchool.bind(planService);
  @task.resolved @action changeArea = planService.changeArea.bind(planService);
}

const planStore = new PlanStore();

export default planStore;
