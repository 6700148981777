import { stringify } from 'qs';
import BaseService from '../base.service';
import { PaginationMeta } from '../common.interface';
import commonStore from '../common.store';
import { ReportSchool } from '../report/report.interface';
import { SchoolCategory } from '../school/school.interface';
import request from '../utils/request';

export class ReportService extends BaseService {
  // 数据上报
  upReport(params: { planId: string; zone: string }) {
    return request(`${this.service}/up-report-sample`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }

  // 按省市区检测点、学校类型、学校、年级、班级查看列表
  list(params: {
    planId: string;
    province?: string;
    city?: string;
    county?: string;
    stationId?: string;
    schoolCategory?: keyof typeof SchoolCategory | 'All';
    schoolId?: string;
    grade?: string;
    clazz?: string;
    age?: boolean;
    [key: string]: any;
  }): Promise<ReportSchool[] | { items: ReportSchool[]; meta: PaginationMeta }> {
    const { planId, province, city, county, stationId, schoolCategory, schoolId, grade, clazz, age, ...rest } = {
      ...commonStore.userAssociatedInfo,
      ...params,
    };
    if (!planId) return Promise.reject('任务ID是必须的');
    if (age) {
      return this.reportAgeList({
        planId,
        province,
        city,
        county,
        stationId,
        schoolCategory: schoolCategory as keyof typeof SchoolCategory,
        schoolId,
        grade,
        clazz,
        ...rest,
      });
    }

    if (schoolCategory === 'All') {
      if (stationId) {
        return this.reportSchoolCategoryList({ planId, stationId, ...rest });
      }
      if (county && city) {
        return this.reportStationSchoolCategoryList({ planId, city, county, ...rest });
      }
      if (city) {
        return this.reportCountySchoolCategoryList({ planId, city, ...rest });
      }
      if (province) {
        return this.reportCitySchoolCategoryList({ planId, province, ...rest });
      }
    }

    if (grade && schoolId) {
      return this.reportClazzList({ planId, schoolId, grade, ...rest });
    }
    if (schoolId) {
      return this.reportGradeList({ planId, schoolId, ...rest });
    }
    if (stationId && schoolCategory && schoolCategory !== 'All') {
      return this.reportGradeList({ planId, stationId, schoolCategory, ...rest });
    }
    if (stationId) {
      return this.reportSchoolList({ planId, stationId, schoolCategory, ...rest });
    }
    if (county && city) {
      return this.reportStationList({ planId, city, county, schoolCategory, ...rest });
    }
    if (city) {
      return this.reportCountyList({ planId, city, schoolCategory, ...rest });
    }
    if (province) {
      return this.reportCityList({ planId, province, schoolCategory, ...rest });
    }

    return Promise.reject('参数不正确');
  }

  // 按省市区检测点、学校类型、学校、年级、班级查看
  get(params: {
    planId: string;
    province?: string;
    city?: string;
    county?: string;
    stationId?: string;
    schoolId?: string;
    grade?: string;
    clazz?: string;
    [key: string]: any;
  }): Promise<ReportSchool> {
    const { planId, city, county, stationId, schoolId, grade, clazz, ...rest } = {
      ...commonStore.userAssociatedInfo,
      ...params,
    };
    if (!planId) return Promise.reject('任务ID是必须的');
    if (clazz && grade && schoolId) {
      return this.reportClazz({ planId, schoolId, grade, clazz, ...rest });
    }
    if (grade && schoolId) {
      return this.reportGrade({ planId, schoolId, grade, ...rest });
    }
    if (schoolId) {
      return this.reportSchool({ planId, schoolId, ...rest });
    }
    if (stationId) {
      return this.reportStation({ planId, stationId, ...rest });
    }
    if (county && city) {
      return this.reportCounty({ planId, city, county, ...rest });
    }
    if (city) {
      return this.reportCity({ planId, city, ...rest });
    }
    // if (province) {
    //   return this.reportCity({ planId, province });
    // }
    return Promise.reject('参数不正确');
  }

  // 按年龄查看列表
  reportAgeList(params: {
    planId: string;
    province?: string;
    city?: string;
    county?: string;
    stationId?: string;
    schoolCategory?: keyof typeof SchoolCategory;
    schoolId?: string;
    grade?: string;
    clazz?: string;
    [key: string]: any;
  }) {
    const { planId, province, city, county, stationId, schoolId, ...rest } = {
      ...commonStore.userAssociatedInfo,
      ...params,
    };
    if (!planId) return Promise.reject('任务ID是必须的');
    if (schoolId) {
      return this.reportSchoolAge({ planId, schoolId, ...rest });
    }
    if (stationId) {
      return this.reportSchoolAgeList({ planId, stationId, ...rest });
    }
    if (county && city) {
      return this.reportStationAgeList({ planId, city, county, ...rest });
    }
    if (city) {
      return this.reportCountyAgeList({ planId, city, ...rest });
    }
    if (province) {
      return this.reportCityAgeList({ planId, province, ...rest });
    }
    return Promise.reject('参数不正确');
  }

  // 市报表列表
  reportCityList(params: { planId: string; province: string; [key: string]: any }) {
    return request(`${this.service}/city-report/school-report-list-sample?${stringify({ size: 10, ...params })}`);
  }
  // 市学校类型列表
  reportCitySchoolCategoryList(params: { planId: string; province: string; [key: string]: any }) {
    return request(`${this.service}/city-report/school-category-report-list-sample?${stringify(params)}`);
  }
  // 市年龄报表
  reportCityAgeList(params: { planId: string; province?: string; [key: string]: any }) {
    return request(`${this.service}/city-report/age-report-list-sample?${stringify(params)}`);
  }
  // 单个市报表
  reportCity(params: { planId: string; city: string; [key: string]: any }) {
    return request(`${this.service}/city-report/school-report-sample?${stringify(params)}`);
  }

  // 区县报表列表
  reportCountyList(params: { planId: string; city: string; [key: string]: any }) {
    return request(`${this.service}/county-report/school-report-list-sample?${stringify({ size: 10, ...params })}`);
  }
  // 区县学校类型列表
  reportCountySchoolCategoryList(params: { planId: string; city: string; [key: string]: any }) {
    return request(`${this.service}/county-report/school-category-report-list-sample?${stringify(params)}`);
  }
  // 区县年龄报表
  reportCountyAgeList(params: { planId: string; city: string; [key: string]: any }) {
    return request(`${this.service}/county-report/age-report-list-sample?${stringify(params)}`);
  }
  // 单个区县报表
  reportCounty(params: { planId: string; county: string; [key: string]: any }) {
    return request(`${this.service}/county-report/school-report-sample?${stringify(params)}`);
  }

  // 检测点报表列表
  reportStationList(params: { planId: string; city: string; county: string; [key: string]: any }) {
    return request(`${this.service}/station-report/school-report-list-sample?${stringify({ size: 10, ...params })}`);
  }
  // 检测点学校类型列表
  reportStationSchoolCategoryList(params: { planId: string; city: string; county: string; [key: string]: any }) {
    return request(`${this.service}/station-report/school-category-report-list-sample?${stringify(params)}`);
  }
  // 检测点年龄报表
  reportStationAgeList(params: { planId: string; county: string; [key: string]: any }) {
    return request(`${this.service}/station-report/age-report-list-sample?${stringify(params)}`);
  }
  // 单个检测点报表
  reportStation(params: { planId: string; stationId: string; [key: string]: any }) {
    return request(`${this.service}/station-report/school-report-sample?${stringify(params)}`);
  }

  // 学校报表列表
  reportSchoolList(params: { planId: string; stationId: string; [key: string]: any }) {
    return request(`${this.service}/school-report/school-report-list-sample?${stringify({ size: 10, ...params })}`);
  }
  // 学校类型列表
  reportSchoolCategoryList(params: { planId: string; stationId: string; [key: string]: any }) {
    return request(`${this.service}/school-report/school-category-report-list-sample?${stringify(params)}`);
  }
  // 学校年龄报表
  reportSchoolAgeList(params: { planId: string; stationId: string; [key: string]: any }) {
    return request(`${this.service}/school-report/station-age-report-list-sample?${stringify(params)}`);
  }
  // 单个学校年龄报表
  reportSchoolAge(params: { planId: string; schoolId: string; grade?: string; clazz?: string; [key: string]: any }) {
    return request(`${this.service}/school-report/age-report-list-sample?${stringify(params)}`);
  }
  // 单个学校报表
  reportSchool(params: { planId: string; schoolId: string; [key: string]: any }) {
    return request(`${this.service}/school-report/school-report-sample?${stringify(params)}`);
  }

  // 年级报表列表
  reportGradeList(params: {
    planId: string;
    stationId?: string;
    schoolCategory?: keyof typeof SchoolCategory;
    schoolId?: string;
    [key: string]: any;
  }) {
    return request(`${this.service}/school-report/grade-report-list-sample?${stringify(params)}`);
  }
  // 单个年级报表
  reportGrade(params: { planId: string; schoolId: string; grade: string; [key: string]: any }) {
    return request(`${this.service}/school-report/grade-report-sample?${stringify(params)}`);
  }

  // 单个班级报表
  reportClazz(params: { planId: string; schoolId: string; grade: string; clazz: string; [key: string]: any }) {
    return request(`${this.service}/school-report/clazz-report-sample?${stringify(params)}`);
  }
  // 班级报表列表
  reportClazzList(params: { planId: string; schoolId: string; grade: string; [key: string]: any }) {
    return request(`${this.service}/school-report/clazz-report-list-sample?${stringify(params)}`);
  }
}

const reportService = new ReportService('/admin/report');

export default reportService;
