import { stringify } from 'qs';
import RESTService from '../rest.service';
import request from '../utils/request';
import { SmsManage } from './manage.interface';

export class ManageService extends RESTService<SmsManage> {
  // 轻派短信列表
  smslist(params: { [key: string]: any }) {
    return request(`${this.service}/qingpai-list?${stringify(params)}`);
  }
  // 其他平台短信列表
  smsOtherlist(params: { stationId: string, result?: string, size?: number, page?: number; [key: string]: any }) {
    return request(`${this.service}?${stringify(params)}`);
  }
  openSms(params: { stationId: string }) {
    return request(`${this.service}/open?${stringify(params)}`);
  }
}

const manageService = new ManageService('/admin/sms');

export default manageService;
