import { stringify } from 'qs';
import planStore from '../plan/plan.store';
import RESTService from '../rest.service';
import request from '../utils/request';
import { Recheck } from './recheck.interface';

export class RecheckService extends RESTService<Recheck> {
  list(params?: { planId?: string; schoolId?: string; title?: string; [key: string]: any }) {
    if (!params?.planId || !params?.schoolId || !params?.title) {
      return Promise.resolve({ items: [], meta: { page: 0, pages: 0, size: 0, total: 0 } });
    }
    const expand = ['school', 'record'].join(',');
    return super.list({ expand, ...params });
  }

  async getSummary(params?: { planId?: string; schoolId?: string; title?: string }) {
    if (!params?.planId || !params?.schoolId || !params?.title) return Promise.resolve({} as any);

    const planType = planStore.entities[params?.planId]?.planType;
    switch (planType) {
      case 'Vision':
        return { vision: await this.getVisionSummary(params as any) };
      case 'CommonDisease':
      case 'Checkup':
        return {
          all: await this.getAllSummary(params as any),
          vision: await this.getVisionSummary(params as any),
          heightWeight: await this.getHeightWeightSummary(params as any),
        };
      // case 'Checkup':
      //   return {
      //     checkup: await this.getCheckupSummary(params as any),
      //   };
    }
  }

  listTitle(params: { planId?: string; schoolId?: string }) {
    if (!params?.planId || !params?.schoolId) {
      return Promise.resolve([]);
    }
    return request(`${this.service}/title-list?${stringify(params, { skipNulls: true })}`);
  }

  getAllSummary(params: { planId: string; schoolId: string; title: string }) {
    return request(`${this.service}/summary-all?${stringify(params, { skipNulls: true })}`);
  }

  getVisionSummary(params: { planId: string; schoolId: string; title: string }) {
    return request(`${this.service}/summary-vision?${stringify(params, { skipNulls: true })}`);
  }

  getHeightWeightSummary(params: { planId: string; schoolId: string; title: string }) {
    return request(`${this.service}/summary-height-weight?${stringify(params, { skipNulls: true })}`);
  }

  getCheckupSummary(params: { planId: string; schoolId: string; title: string }) {
    return request(`${this.service}/summary-checkup?${stringify(params, { skipNulls: true })}`);
  }
}

const recheckService = new RecheckService('/admin/retest');

export default recheckService;
