import { stringify } from 'qs';
import RESTService from '../rest.service';
import request from '../utils/request';
import { Student } from './student.interface';

export class StudentService extends RESTService<Student> {
  list(params?: { [key: string]: any }) {
    // if (!params?.schoolId) return Promise.resolve({ items: [], meta: { page: 0, pages: 0, size: 0, total: 0 } });
    const expand = ['fullId', 'school', params?.planId && 'record', window.CONFIG.checkExportClearIdcard && 'export'].filter(Boolean).join(',');
    return super.list({ expand, ...params });
  }
  get(id: string, params?: { [key: string]: any }) {
    const expand = ['fullId', 'school'].join(',');
    return super.get(id, { expand, ...params });
  }
  create(params: any) {
    return request(`${this.service}`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }
  update(id: string, params: any) {
    return request(`${this.service}/${id}`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'PATCH',
    });
  }
  // 导入预览
  batchInsertPreview(params: { schoolId: string; data: any[] }) {
    return request(`${this.service}/batch-insert`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }
  // 批量导入
  batchInsert(params: { addList: any[]; updateList: any[]; }) {
    return request(`${this.service}/import-data`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }
  batchUpdate(params: { ids: string[]; grade?: string; clazz?: string; nation?: string; birthPlace?: string; status?: string }) {
    return request(`${this.service}/batch-update`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'PATCH',
    });
  }
  // removeAll(params: { schoolId: string }) {
  //   return request(`${this.service}/removeAll?${stringify(params, { skipNulls: true })}`, {
  //     method: 'delete',
  //   });
  // }
  listGrade(params: { schoolId: string }) {
    return request(`${this.service}/grade-list?${stringify({ ...params })}`);
  }
  listClazz(params: { schoolId: string; grade: string }) {
    return request(`${this.service}/clazz-list?${stringify({ ...params })}`);
  }
  delete(params: { [key: string]: any }) {
    return request(`${this.service}/delete`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }
  // 获取学生的所有复诊数据
  getReferralList(params: {id: string} ) {
    const expand = ['fullId', 'school', 'revisit'].join(',');
    // return super.get(params.id, { expand });
    // get(id: number | string, params?: { [key: string]: any }): Promise<T> {
      return request(`${this.service}/${params.id}?${stringify({ expand }, { skipNulls: true })}`);
    // }
  }

  reportStudentReferralList(params: { schoolId?: string; grade?: string; clazz?: string; studentId?: string }) {
    if (params.studentId) {
      return this.getReferralList({ id: params.studentId });
    }
    return Promise.resolve([]);
  }

}

const studentService = new StudentService('/admin/student');

export default studentService;
