import { stringify } from 'qs';
import RESTService from '../rest.service';
import request from '../utils/request';
import { SmsGroup } from './group.interface';

export class SmsConfigService extends RESTService<SmsGroup> {  
  // 查询当前客户信息
  getClient(params: { stationId: string }) {
    return request(`${this.service}/app?${stringify(params)}`);
  }
  // 客户短信密钥配置
  createConfig(params: {
    stationId: string,
    appId: string,
    appSecret: string,
  }) {
    return request(`${this.service}`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }
  // 客户短信密钥按区县或市,省配置(已存在密钥将被覆盖)
  updateAreaConfig(params: {
    province: string;
    city: string;
    county: string;
    appId: string;
    appSecret: string;
    overwrite: boolean;
  }) {
    return request(`${this.service}/sms-config/area`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }
}

const smsConfigService = new SmsConfigService('/admin/sms-config');

export default smsConfigService;