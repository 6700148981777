import { stringify } from 'qs';
import BaseService from '../base.service';
import request from '../utils/request';

export class ReportParentService extends BaseService {
  // 家长查询
  getSummary(params: {
    stationId?: string;
    schoolId?: string;
    createDate?: string;
  }) {
    return request(`${this.service}/parent/query?${stringify(params)}`);
  }

  // 预约转化
  getAppoint(params: {
    stationId?: string;
    appointmentDate?: string;
  }) {
    return request(`${this.service}/hostpital-appointment/report?${stringify(params)}`);
  }

  // 导入预约数据
  reserveInsert(params: { stationId: string; data: any[] }) {
    return request(`${this.service}/hostpital-appointment/import`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }
}

const reportParentService = new ReportParentService('/admin');

export default reportParentService;
