import { action, computed, observable, set } from 'mobx';
import { task } from 'mobx-task';
import { normalize, schema } from 'normalizr';
import { stringify } from 'qs';
import { defaultPagination } from '../config';
import sampleRecordService from '../sample_record/sample_record.service';
import { Recheck, RecheckSummary, RecheckTitle } from './recheck.interface';
import recheckService from './recheck.service';
export const recheckSchema = new schema.Entity('recheck');

export class RecheckStore {
  @observable mode?: 'sample';
  @observable query?: { [key: string]: any };
  @observable entities: { [key: string]: Recheck } = {};
  @observable ids: { [qs: string]: number[] } = {};
  @observable pgs: { [qs: string]: { [key: string]: number } } = {};
  @observable summaries: {
    [qs: string]: {
      all?: RecheckSummary;
      vision?: RecheckSummary;
      heightWeight?: RecheckSummary;
      checkup?: RecheckSummary;
    };
  } = {};
  @observable titles: { [qs: string]: RecheckTitle[] } = {};

  @computed get dataSource() {
    return (this.ids[stringify(this.query, { sort: (a, b) => a.localeCompare(b) })] || []).map(
      (id) => this.entities[id],
    );
  }

  @computed get pagination() {
    const pagination = this.pgs[stringify(this.query, { sort: (a, b) => a.localeCompare(b) })];
    return (
      !!pagination && {
        current: pagination.page + 1,
        pageSize: pagination.size,
        total: pagination.total,
        ...defaultPagination,
      }
    );
  }

  @action updateEntities(entities: { [key: string]: Recheck } = {}) {
    const ids = Object.keys(entities);
    for (const id of ids) {
      this.entities = { ...this.entities, [id]: { ...this.entities[id], ...entities[id] } }; // mobx4
      // this.entities[id] = { ...this.entities[id], ...entities[id] }; // mobx5
    }
  }

  @action relist(query: Parameters<typeof recheckService.list>[0], reload = false) {
    return this.list({ ...this.query, ...query }, reload);
  }

  @task.resolved @action async list(query: Parameters<typeof recheckService.list>[0], reload = false) {
    this.query = { ...query, mode: this.mode };
    const qs = stringify(this.query, { sort: (a, b) => a.localeCompare(b) });

    // 如果列表已加载且不刷新，则使用缓存
    if (this.ids[qs] && !reload) {
      return {
        items: this.ids[qs].map((id) => this.entities[id]),
        meta: this.pgs[qs],
      };
    }

    const listMethod =
      ({
        sample: sampleRecordService.listByType.bind(sampleRecordService, 'recheck'),
      } as any)[this.query?.mode!] || recheckService.list.bind(recheckService);

    const data = await listMethod(this.query);
    const { result, entities } = normalize(data.items, [recheckSchema]);
    this.updateEntities(entities.recheck);
    set(this, {
      ids: reload ? { [qs]: result } : { ...this.ids, [qs]: result },
      pgs: reload ? { [qs]: data.meta } : { ...this.pgs, [qs]: data.meta },
    });

    return data;
  }

  @task.resolved @action async getSummary(query: Parameters<typeof recheckService.getSummary>[0], reload = false) {
    const qs = stringify(query, { sort: (a, b) => a.localeCompare(b) });
    if (this.summaries[qs] && !reload) {
      return this.summaries[qs];
    }
    // this.summaries[qs] = await recheckService.getSummary(query); // mobx5
    set(this.summaries, qs, await recheckService.getSummary(query)); // mobx4
  }

  @task.resolved @action async listTitle(query: Parameters<typeof recheckService.listTitle>[0], reload = false) {
    const qs = stringify(query, { sort: (a, b) => a.localeCompare(b) });
    if (this.titles[qs] && !reload) {
      return this.titles[qs];
    }
    // this.titles[qs] = await recheckService.listTitle(query);  // mobx5
    this.titles = { ...this.titles, [qs]: await recheckService.listTitle(query) }; // mobx4
  }

  @task.resolved @action async get(...args: Parameters<typeof recheckService.get>) {
    const data = await recheckService.get(...args);
    const { entities } = normalize(data, recheckSchema);
    this.updateEntities(entities.recheck);
    return data;
  }
  @task.resolved @action async create(...args: Parameters<typeof recheckService.create>) {
    const data = await recheckService.create(...args);
    const { entities } = normalize(data, recheckSchema);
    this.updateEntities(entities.recheck);
    return data;
  }
  @task.resolved @action async update(...args: Parameters<typeof recheckService.update>) {
    const data = await recheckService.update(...args);
    const { entities } = normalize(data, recheckSchema);
    this.updateEntities(entities.recheck);
    return data;
  }
  @task.resolved @action remove = recheckService.remove.bind(recheckService);
}

const recheckStore = new RecheckStore();

export default recheckStore;
