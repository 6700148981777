import { stringify } from 'qs';
import { PaginationMeta } from '../common.interface';
import { QuestionnaireType } from '../questionnaire/questionnaire.interface';
import RESTService from '../rest.service';
import studentService from '../student/student.service';
import request from '../utils/request';
import { Check } from './check.interface';

export class CheckService extends RESTService<Check> {
  list(params?: { [key: string]: any }) {
    if (!params?.planId) return Promise.resolve({ items: [], meta: { page: 0, pages: 0, size: 0, total: 0 } });
    if (params?.status === 'All') {
      return studentService
        .list({
          ...params,
          status: undefined,
          expand: [
            'fullId',
            'school',
            'record',
            window.CONFIG.checkExportClearIdcard && 'export',
            ...(params?.expand ?? []),
          ]
            .filter(Boolean)
            .join(','),
        })
        .then(({ items, meta }) => ({
          items: items.map((item) => ({
            ...item,
            ...item.record,
            fullId: item.record?.fullId || item.fullId,
            school: item.school,
          })),
          meta,
        })) as Promise<{
        items: Check[];
        meta: PaginationMeta;
      }>;
    }
    if (params?.status === 'Undo') {
      return studentService.list({
        ...params,
        status: undefined,
        state: 'uncheck',
        expand: [
          'fullId',
          'school',
          'record',
          window.CONFIG.checkExportClearIdcard && 'export',
          ...(params?.expand ?? []),
        ]
          .filter(Boolean)
          .join(','),
      }) as Promise<{
        items: Check[];
        meta: PaginationMeta;
      }>;
    }
    if(params?.hzParentQueryRecord) {
      return super.list({
        ...params,
        hzParentQueryRecord: undefined
      });
    }
    return super.list({
      ...params,
      expand: [
        'fullId',
        'school',
        window.CONFIG.checkExportClearIdcard && 'export',
        window.CONFIG.checkExportQuestionnaire && 'questionnaire',
        ...(params?.expand ?? []),
      ]
        .filter(Boolean)
        .join(','),
    });
  }
  import(params: {
    fromPlanId: string;
    toPlanId: string;
    itemKeyList?: string[];
    questionnaireType?: QuestionnaireType[];
    schoolIdList: string[];
  }) {
    return request(`${this.service}/import-data`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }
  importExternal(params: { planId: string; data: string[] }) {
    return request(`${this.service}/batch-insert`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }
  historyRecord(params: { studentId: string, expand: 'school' }) {
    return request(`${this.service}/history?${stringify(params)}`);
  }
  delete(params: { [key: string]: any }) {
    return request(`${this.service}/delete`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }
}

const checkService = new CheckService('/admin/record');

export default checkService;
