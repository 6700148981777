import commonStore from '../common.store';
import { Plan } from '../plan/plan.interface';
import RESTService from '../rest.service';
import { School } from '../school/school.interface';
import { Station } from '../station/station.interface';
import request from '../utils/request';
import { User } from './user.interface';

const expand = ['station', 'school'].join(',');

export class UserService extends RESTService<User> {
  list(params?: { [key: string]: any }) {
    return super.list({ expand, ...commonStore.userAssociatedInfo, ...params });
  }
  get(id: string, params?: { [key: string]: any }): Promise<User> {
    return super.get(id, { expand, ...params });
  }
  getCurrentUser(): Promise<User> {
    return request(`${this.service}/current-user`);
  }
  getCurrentPlan(): Promise<Plan> {
    return request(`${this.service}/current-plan`);
  }
  getCurrentStation(): Promise<Station> {
    return request(`${this.service}/current-station`);
  }
  getCurrentSchool(): Promise<School> {
    return request(`${this.service}/current-school`);
  }
  upadtePassword(id: string, params: { originalPassword: string; password: string }) {
    return request(`${this.service}/${id}/password`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'PATCH',
    });
  }
  cancelForbid(id: string) {
    return request(`${this.service}/${id}/cancelForbid`, { method: 'PATCH' });
  }
}

const userService = new UserService('/admin/user');

export default userService;
