import { stringify } from 'qs';
import RESTService from '../rest.service';
import request from '../utils/request';
import { Sickness, SicknessReportItem } from './sickness.interface';

const expand = ['school', 'student'].join(',');

export class SicknessService extends RESTService<Sickness> {
  list(params?: { [key: string]: any }) {
    return super.list({ expand, ...params });
  }
  report(params: {
    city?: string; // 	市
    county?: string; // 	县
    stationId?: string; // 	监测点id
    schoolId?: string; // 	学校Id
    createdDate?: string; // 查询范围
  }): Promise<SicknessReportItem[]> {
    return request(`${this.service}/report?${stringify(params)}`);
  }
}

const sicknessService = new SicknessService('/admin/sickness');

export default sicknessService;
