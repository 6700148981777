import BaseService from '../base.service';
import request from '../utils/request';

export class AuthService extends BaseService {
  login(params: { username: string; password: string; encrypt: boolean }) {
    return request(`${this.service}`, {
      body: JSON.stringify({ client: 'browser', deviceInfo: window.navigator.userAgent, ...params }),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
      credentials: 'omit',
    });
  }
}

const authService = new AuthService('/auth/v1/admin');

export default authService;
