import { stringify } from 'qs';
import BaseService from '../base.service';
import { PaginationMeta } from '../common.interface';
import commonStore from '../common.store';
import { ReportRecheck } from '../report/report.interface';
import request from '../utils/request';

export class ReportRecheckService extends BaseService {
  // 按省市区检测点查看列表
  list(params: {
    planId: string;
    province?: string;
    city?: string;
    county?: string;
    stationId?: string;
    [key: string]: any;
  }): Promise<ReportRecheck[] | { items: ReportRecheck[]; meta: PaginationMeta }> {
    const { planId, province, city, county, stationId, ...rest } = {
      ...commonStore.userAssociatedInfo,
      ...params,
    };
    if (!planId) return Promise.reject('任务ID是必须的');

    if (stationId) {
      return this.reportSchoolList({ planId, stationId, ...rest });
    }
    if (county && city) {
      return this.reportStationList({ planId, city, county, ...rest });
    }
    if (city) {
      return this.reportCountyList({ planId, city, ...rest });
    }
    if (province) {
      return this.reportCityList({ planId, province, ...rest });
    }

    return Promise.reject('参数不正确');
  }

  // 按省市区检测点学校查看
  get(params: {
    planId: string;
    province?: string;
    city?: string;
    county?: string;
    stationId?: string;
    schoolId?: string;
    [key: string]: any;
  }): Promise<ReportRecheck> {
    const { planId, city, county, stationId, schoolId } = {
      ...commonStore.userAssociatedInfo,
      ...params,
    };
    if (!planId) return Promise.reject('任务ID是必须的');
    if (schoolId) {
      return this.reportSchool({ planId, schoolId });
    }
    if (stationId) {
      return this.reportStation({ planId, stationId });
    }
    if (county && city) {
      return this.reportCounty({ planId, city, county });
    }
    if (city) {
      return this.reportCity({ planId, city });
    }
    // if (province) {
    //   return this.reportCity({ planId, province });
    // }
    return Promise.reject('参数不正确');
  }

  // 市报表列表
  reportCityList(params: { planId: string; province: string; [key: string]: any }) {
    return request(`${this.service}/city-report/retest-report-list-sample?${stringify({ size: 10, ...params })}`);
  }
  // 单个市报表
  reportCity(params: { planId: string; city: string; [key: string]: any }) {
    return request(`${this.service}/city-report/retest-report-sample?${stringify(params)}`);
  }

  // 区县报表列表
  reportCountyList(params: { planId: string; city: string; [key: string]: any }) {
    return request(`${this.service}/county-report/retest-report-list-sample?${stringify({ size: 10, ...params })}`);
  }
  // 单个区县报表
  reportCounty(params: { planId: string; county: string; [key: string]: any }) {
    return request(`${this.service}/county-report/retest-report-sample?${stringify(params)}`);
  }

  // 区县报表列表
  reportStationList(params: { planId: string; city: string; county: string; [key: string]: any }) {
    return request(`${this.service}/station-report/retest-report-list-sample?${stringify({ size: 10, ...params })}`);
  }
  // 单个检测点报表
  reportStation(params: { planId: string; stationId: string; [key: string]: any }) {
    return request(`${this.service}/station-report/retest-report-sample?${stringify(params)}`);
  }

  // 学校报表列表
  reportSchoolList(params: { planId: string; stationId: string; [key: string]: any }) {
    return request(`${this.service}/school-report/retest-report-list-sample?${stringify({ size: 10, ...params })}`);
  }
  // 单个学校报表
  reportSchool(params: { planId: string; schoolId: string; [key: string]: any }) {
    return request(`${this.service}/school-report/retest-report-sample?${stringify(params)}`);
  }
  // 问卷报表
  reportQuestionnaire(params: { planId: string; schoolId: string; [key: string]: any }) {
    return request(`${this.service}/school-report/retest-report-sample?${stringify(params)}`);
  }
}

const reportRecheckService = new ReportRecheckService('/admin/report');

export default reportRecheckService;
