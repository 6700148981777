import { stringify } from 'qs';
import RESTService from '../rest.service';
import { SicknessReportItem } from '../sickness/sickness.interface';
import request from '../utils/request';
import { SicknessYearForm } from './sickness_year_form.interface';

export class SicknessYearFormService extends RESTService<SicknessYearForm> {
  report(params: {
    province?: string; // 	省
    city?: string; // 	市
    county?: string; // 	县
    year?: string; // 自然年
  }): Promise<SicknessReportItem[]> {
    return request(`${this.service}/summary-report?${stringify(params)}`);
  }
  import(params: {
    province?: string; // 	省
    city?: string; // 	市
    county?: string; // 	县
    year?: string; // 自然年
    data: any[]
  }) {
    return request(`${this.service}/import`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }
}

const sicknessYearFormService = new SicknessYearFormService('/admin/sickness-year-form');

export default sicknessYearFormService;
