import { stringify } from 'qs';
import RESTService from '../rest.service';
import request from '../utils/request';
import { ScienceManage } from './science.interface';

export class ScienceService extends RESTService<ScienceManage> {
  list(params?: any) {
    return request(`${this.service}?${stringify(params)}`);
  }
  get(id: string) {
    return request(`${this.service}/${id}`);
  }
  create(params?: any) {
    return request(`${this.service}`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }
  update(id: string, params: any) {
    return request(`${this.service}/${id}`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'PATCH',
    });
  }
  remove(ids: string, params?: any) {
    return request(`${this.service}/${ids}?${stringify(params, { skipNulls: true })}`, {
      method: 'delete',
    });
  }
}

const scienceService = new ScienceService('/admin/science-doc');

export default scienceService;
