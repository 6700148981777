import { Check } from '../check/check.interface';
import { PaginationMeta } from '../common.interface';
import studentService from '../student/student.service';
import RESTService from '../rest.service';

export class ReportRecordService extends RESTService<Check> {
  list(params?: { [key: string]: any }) {
    if (!params?.planId) return Promise.resolve({ items: [], meta: { page: 0, pages: 0, size: 0, total: 0 } });
    if (params?.status === 'All') {
      return studentService
        .list({
          ...params,
          status: undefined,
          expand: [
            'fullId',
            'school',
            'record',
            window.CONFIG.checkExportClearIdcard && 'export',
            ...(params?.expand ?? []),
          ]
            .filter(Boolean)
            .join(','),
        })
        .then(({ items, meta }) => ({
          items: items.map((item) => ({
            ...item,
            ...item.record,
            fullId: item.record?.fullId || item.fullId,
            school: item.school,
          })),
          meta,
        })) as Promise<{
        items: Check[];
        meta: PaginationMeta;
      }>;
    }
    if (params?.status === 'Undo') {
      return studentService.list({
        ...params,
        status: undefined,
        state: 'uncheck',
        expand: [
          'fullId',
          'school',
          'record',
          window.CONFIG.checkExportClearIdcard && 'export',
          ...(params?.expand ?? []),
        ]
          .filter(Boolean)
          .join(','),
      }) as Promise<{
        items: Check[];
        meta: PaginationMeta;
      }>;
    }
    return super.list({
      ...params,
      expand: [
        'fullId',
        'school',
        window.CONFIG.checkExportClearIdcard && 'export',
        window.CONFIG.checkExportQuestionnaire && 'questionnaire',
        ...(params?.expand ?? []),
      ]
        .filter(Boolean)
        .join(','),
    });
  }
}

const reportRecordService = new ReportRecordService('/admin/sample-record/up');

export default reportRecordService;
