import { Modal } from 'antd';
import { sessionStorage } from './storage';

export default function request(url: string, options: any = {}) {
  const args = getNewUrlAndOptions(url, options);
  return fetch(args.url, args.options).then(checkStatus).then(parseJSON).then(checkCode).catch(errHandler);
}

export function requestBlob(url: string, options: any = {}) {
  const args = getNewUrlAndOptions(url, options);
  return fetch(args.url, args.options).then(checkStatus).then(parseBlob).catch(errHandler);
}

function getNewUrlAndOptions(url: string, options: any = {}) {
  let newUrl = url;
  let newOptions = options;
  if (url.indexOf('http') !== 0) {
    const token = sessionStorage.get('token');

    if(url.indexOf('common') === 1) {
      newUrl = url
    } else {
      newUrl = '/api' + url;
    }
    
    newOptions = {
      ...options,
      headers: {
        Accept: 'application/json',
        ...(!!token && { Authorization: `Bearer ${token}` }),
        ...options.headers,
      },
    };
  }

  return { url: newUrl, options: newOptions };
}

async function checkStatus(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  try {
    const json = await response.json();
    return Promise.reject({
      code: json.code ?? response.status,
      message: json.message ?? response.statusText,
    });
  } catch {
    return Promise.reject({
      code: response.status,
      message: response.statusText,
    });
  }
}

function parseJSON(response: any) {
  return response.json();
}

function parseBlob(response: any) {
  return response.blob();
}

function checkCode(json: { code: number; message: string; data: any }) {
  if (json.code == null) {
    return json;
  }

  if (json.code === 0) {
    return json.data;
  }

  return Promise.reject({ code: json.code, message: json.message });
}

async function errHandler(error: { code: number; message: string }) {
  if (error.code === 401) {
    Modal.error({
      title: '认证失效',
      content: '您的账号已在其他地方登录，您已经被强制下线。',
      onOk: () => {
        sessionStorage?.clearAll();
        window.location.reload();
      },
    });
    throw error;
  }

  // message.error(error.message || '请求发生错误', 0);
  Modal.error({
    title: '提示',
    content: error.message || '请求发生错误',
  })
  throw error;
}
