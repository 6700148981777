const history = window.history;
history.action = '';

export default function patchHistory() {
  const go = history.go;
  const back = history.back;
  const forward = history.forward;
  const pushState = history.pushState;
  const replaceState = history.replaceState;

  history.go = function _go(...args) {
    history.action = 'go';
    return go.apply(history, args);
  };
  history.back = function _back(...args) {
    history.action = 'back';
    return back.apply(history, args);
  };
  history.forward = function _forward(...args) {
    history.action = 'forward';
    return forward.apply(history, args);
  };
  history.pushState = function _pushState(...args) {
    history.action = 'push';
    return pushState.apply(history, args);
  };
  history.replaceState = function _replaceState(...args) {
    history.action = 'replace';
    return replaceState.apply(history, args);
  };

  window.addEventListener(
    'popstate',
    () => {
      history.action = 'pop';
    },
    false
  );
}
