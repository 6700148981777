import { stringify } from 'qs';
import RESTService from '../rest.service';
import request from '../utils/request';
import { SmsTemplate } from './template.interface';

export class SmsService extends RESTService<SmsTemplate> {
  list(params?: any) {
    return super.list(params);
  }
  get(id: string, params?: { [key: string]: any }) {
    return super.get(id, { params });
  }
  update(id: string, params?: { [key: string]: any }) {
    return request(`${this.service}/${id}`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'PATCH',
    });
  }
  create(params: { stationId: string; type: string; content: string; remark: string }) {
    return request(`${this.service}`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }
  remove(id: string) {
    return request(`${this.service}/${id}`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'delete',
    });
  }
  // 类型列表
  typelist(params:{ stationId: string }) {
    return request(`${this.service}/type-list?${stringify(params)}`);
  }
  // 初始化自定义模板
  initTemplatelist(params:{ stationId: string }) {
    return request(`${this.service}/smsTemplateInit?${stringify(params)}`);
  }
}

const smsService = new SmsService('/admin/sms-template');

export default smsService;
