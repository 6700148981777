import { PaginationMeta } from '../common.interface';
import RESTService from '../rest.service';
import { Reviewfiles } from './reviewfiles.interface'

export class ReviewfilesService extends RESTService<Reviewfiles> {
  list(params?: { [key: string]: any }) {
    return super.list({
      ...params,
      revisitType: 'Student',
      expand: [
        'school',
        window.CONFIG.checkExportClearIdcard && 'export',
        ...(params?.expand ?? []),
      ]
        .filter(Boolean)
        .join(','),
    })
    .then(({ items, meta }) => ({
      items: items.map((item) => ({
        ...item,
        ...item.data,
        school: item.school,
      })),
      meta,
    })) as Promise<{
    items: Reviewfiles[];
    meta: PaginationMeta;
  }>;
  }
}

const reviewfilesService = new ReviewfilesService('/admin/revisit');

export default reviewfilesService;
