import { action, computed, observable, set } from 'mobx';
import { task } from 'mobx-task';
import { normalize, schema } from 'normalizr';
import { stringify } from 'qs';
import { defaultPagination } from '../config';
import { Station } from './station.interface';
import stationService from './station.service';

export const stationSchema = new schema.Entity('station');

export class StationStore {
  @observable query?: { [key: string]: any };
  @observable entities: { [key: string]: Station } = {};
  @observable ids: { [qs: string]: number[] } = {};
  @observable pgs: { [qs: string]: { [key: string]: number } } = {};

  @computed get dataSource() {
    return (this.ids[stringify(this.query, { sort: (a, b) => a.localeCompare(b) })] || []).map(
      (id) => this.entities[id],
    );
  }

  @computed get pagination() {
    const pagination = this.pgs[stringify(this.query, { sort: (a, b) => a.localeCompare(b) })];
    return (
      !!pagination && {
        current: pagination.page + 1,
        pageSize: pagination.size,
        total: pagination.total,
        ...defaultPagination,
      }
    );
  }

  @action updateEntities(entities: { [key: string]: Station } = {}) {
    const ids = Object.keys(entities);
    for (const id of ids) {
      this.entities = { ...this.entities, [id]: { ...this.entities[id], ...entities[id] } }; // mobx4
      // this.entities[id] = { ...this.entities[id], ...entities[id] }; // mobx5
    }
  }

  @action relist(query: Parameters<typeof stationService.list>[0], reload = false) {
    return this.list({ ...this.query, ...query }, reload);
  }

  @task.resolved @action async list(query: Parameters<typeof stationService.list>[0], reload = false) {
    this.query = query;
    const qs = stringify(this.query, { sort: (a, b) => a.localeCompare(b) });

    // 如果列表已加载且不刷新，则使用缓存
    if (this.ids[qs] && !reload) {
      return {
        items: this.ids[qs].map((id) => this.entities[id]),
        meta: this.pgs[qs],
      };
    }

    const data = await stationService.list(this.query);
    const { result, entities } = normalize(data.items, [stationSchema]);
    this.updateEntities(entities.station);
    set(this, {
      ids: reload ? { [qs]: result } : { ...this.ids, [qs]: result },
      pgs: reload ? { [qs]: data.meta } : { ...this.pgs, [qs]: data.meta },
    });

    return data;
  }

  @task.resolved @action async get(...args: Parameters<typeof stationService.get>) {
    const data = await stationService.get(...args);
    const { entities } = normalize(data, stationSchema);
    this.updateEntities(entities.station);
    return data;
  }
  @task.resolved @action async create(...args: Parameters<typeof stationService.create>) {
    const data = await stationService.create(...args);
    const { entities } = normalize(data, stationSchema);
    this.updateEntities(entities.station);
    return data;
  }
  @task.resolved @action async update(...args: Parameters<typeof stationService.update>) {
    const data = await stationService.update(...args);
    const { entities } = normalize(data, stationSchema);
    this.updateEntities(entities.station);
    return data;
  }
  @task.resolved @action remove = stationService.remove.bind(stationService);
}

const stationStore = new StationStore();

export default stationStore;
