import { stringify } from 'qs';
import BaseService from '../base.service';
import { PaginationMeta } from '../common.interface';
import request from '../utils/request';
import { Log } from './log.interface';

export class LogService extends BaseService {
  listSysLog(params?: { [key: string]: any }): Promise<{ items: Log[]; meta: PaginationMeta }> {
    return request(`${this.service}/sysLog?${stringify({ size: 10, ...params }, { skipNulls: true })}`);
  }
  listCheckLog(params?: { [key: string]: any }): Promise<{ items: Log[]; meta: PaginationMeta }> {
    return request(`${this.service}/checkLog?${stringify({ size: 10, ...params }, { skipNulls: true })}`);
  }
}

const logService = new LogService('/admin/log');

export default logService;
