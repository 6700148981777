import { PaginationMeta } from '../common.interface';
import RESTService from '../rest.service';
import { Check } from '../check/check.interface';


export class PatientService extends RESTService<Check> {
  list(params?: { [key: string]: any }) {
    return super.list({
      ...params,
      // expand: [
      //   'school',
      //   ...(params?.expand ?? []),
      // ]
      //   .filter(Boolean)
      //   .join(','),
    })
    .then(({ items, meta }) => ({
      items: items.map((item) => ({
        ...item,
        ...item.data,
        school: item.school,
      })),
      meta,
    })) as Promise<{
      items: Check[];
      meta: PaginationMeta;
    }>;
  }
}

const patientService = new PatientService('/admin/patient-record');

export default patientService;
