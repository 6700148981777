import { stringify } from 'qs';
import commonStore from '../common.store';
import RESTService from '../rest.service';
import { SchoolCategory } from '../school/school.interface';
import request from '../utils/request';
import {
  Questionnaire,
  QuestionnaireReport,
  QuestionnaireType,
  ReportQuestionnaireSummary,
} from './questionnaire.interface';

class QuestionnaireService extends RESTService<Questionnaire> {
  list(params?: { [key: string]: any }) {
    if (!params?.planId) {
      return Promise.resolve({ items: [], meta: { page: 0, pages: 0, size: 0, total: 0 } });
    }
    // 待提交 https://www.teambition.com/task/5f6c0692f97ab20044f0628c
    if (params?.state === 'unfinish' && !params?.schoolId) {
      return Promise.resolve({ items: [], meta: { page: 0, pages: 0, size: 0, total: 0 } });
    }
    if(params?.type === 98) {
      return super.list({ expand: 'student,fullId', ...params });
    }
    return super.list({ expand: 'student,fullId', ...commonStore.userAssociatedInfo, ...params });
  }
  get(id: string, params?: { [key: string]: any }) {
    return super.get(id, { expand: 'student,fullId', ...params });
  }
  verifyStudent(params: { name?: string; idcard?: string; studentId?: string; }) {
    return request(`/nauth/record/verify-student?${stringify(params)}`);
  }
  vertifyStation(id: string, params?: { [key: string]: any }) {
    return request(`/nauth/station/${id}?${stringify(params)}`);
  }
  submit(params: Partial<Questionnaire>) {
    return request('/nauth/v1/questionnaire', {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }
  report(params: {
    type: QuestionnaireType;
    province?: string;
    city?: string;
    county?: string;
    stationId?: string;
    planId: string;
    schoolId?: string;
    schoolCategory?: keyof typeof SchoolCategory;
    year?: number;
  }): Promise<QuestionnaireReport[]> {
    params = { ...commonStore.userAssociatedInfo, ...params };
    if (!params?.planId || (~[30, 31].indexOf(params.type) && !params?.schoolCategory && !params?.schoolId)) {
      return Promise.resolve([]);
    }
    return request(`${this.service}/questionnaire-report?${stringify(params)}`);
  }

  // 教学环境监测报表
  reportClassroomQuality(params: {
    type: 40 | 41;
    province?: string;
    city?: string;
    county?: string;
    planId?: string;
    stationId?: string;
    schoolId?: string;
  }) {
    params = { ...commonStore.userAssociatedInfo, ...params };
    if (!params?.planId) {
      return Promise.resolve({} as any);
    }
    return request(`${this.service}/classroom-quality-report?${stringify(params)}`);
  }

  // 学生心理因素
  reportStudentPsychology(params: {
    province?: string;
    city?: string;
    county?: string;
    planId?: string;
    stationId?: string;
    schoolId?: string;
    schoolCategory?: keyof typeof SchoolCategory;
  }) {
    params = { ...commonStore.userAssociatedInfo, ...params };
    if (!params?.planId || (!params?.schoolCategory && !params?.schoolId)) {
      return Promise.resolve({} as any);
    }
    return request(`${this.service}/psychology-report?${stringify(params)}`);
  }

  reportSummary(params: {
    planId: string;
    type: QuestionnaireType;
    province?: string;
    city?: string;
    county?: string;
    stationId?: string;
  }): Promise<ReportQuestionnaireSummary> {
    params = { ...commonStore.userAssociatedInfo, ...params };
    if (!params?.planId) return Promise.resolve({} as any);
    return request(`${this.service}/summary-report?${stringify(params)}`);
  }

  reportSchoolGradeClazz(params: {
    planId: string;
    type: QuestionnaireType;
    stationId?: string;
    schoolId?: string;
    grade?: string;
  }): Promise<any> {
    const { planId, type, stationId, schoolId, grade } = { ...commonStore.userAssociatedInfo, ...params };
    if (!params?.planId) return Promise.resolve([]);

    if (grade && schoolId) {
      return request(`${this.service}/clazz-report?${stringify({ planId, type, schoolId, grade })}`);
    }
    if (schoolId) {
      return request(`${this.service}/grade-report?${stringify({ planId, type, schoolId })}`);
    }
    if (stationId) {
      return request(`${this.service}/school-report?${stringify({ planId, type, stationId })}`);
    }

    return Promise.resolve({});
  }

  // 饮用水合格率监测报表
  reportSchoolDrinkingWater(params: {
    planId?: string;
    stationId?: string;
    city?: string;
    county?: string;
  }) {
    params = { ...commonStore.userAssociatedInfo, ...params };
    if (!params?.planId) {
      return Promise.resolve({} as any);
    }
    return request(`${this.service}/water-quality-report?${stringify(params)}`);
  }
}

const questionaireService = new QuestionnaireService('/admin/questionnaire');

export default questionaireService;
