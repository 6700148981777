import { stringify } from 'qs';
import commonStore from '../common.store';
import RESTService from '../rest.service';
import request from '../utils/request';
import { Community } from './community.interface';

export class CommunityService extends RESTService<Community> {
  list(params?: { [key: string]: any }) {
    return super.list({ ...commonStore.userAssociatedInfo, ...params });
  }
  get(id: string, params?: { [key: string]: any }) {
    return super.get(id, { ...params });
  }
  listStreet(params: { county: string }) {
    return request(`${this.service}/street-list?${stringify(params, { skipNulls: true })}`);
  }
}

const communityService = new CommunityService('/admin/community');

export default communityService;
