import { stringify } from 'qs';
import RESTService from '../rest.service';
import request from '../utils/request';
import { SmsGroup } from './group.interface';

export class GroupService extends RESTService<SmsGroup> {  
  batchList(
    stationId: string,
    planId: string,
    schoolId?: string,
    grade?: string,
    clazz?: string,   
    params?: string[],
    size?: number,
    page?: number,
    keyword?: string
  ) {
    return request(`${this.service}/record-list?stationId=${stationId ?? ''}&planId=${planId ?? ''}&schoolId=${schoolId ?? ''}&grade=${grade ?? ''}&clazz=${clazz ?? ''}${keyword ? `&keyword=${keyword}` : ''}&size=${size ?? ''}&page=${page ?? ''}&expand=student`, {
      body: JSON.stringify(params ?? []),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }
  batchSend(
    stationId: string,
    planId: string,      
    smsTemplateId: string,
    schoolId?: string,
    grade?: string,
    clazz?: string,
    timerTime?: string,
    smsBalance?: number,
    params?: any[],
    keyword?: string
  ) {
    return request(`${this.service}/batch-send?stationId=${stationId ?? ''}&planId=${planId ?? ''}&smsTemplateId=${smsTemplateId ?? ''}&schoolId=${schoolId ?? ''}&grade=${grade ?? ''}&clazz=${clazz ?? ''}${keyword ? `&keyword=${keyword}` : ''}&timerTime=${timerTime ?? ''}&smsBalance=${smsBalance ?? ''}`, {
      body: JSON.stringify(params ?? []),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }
  customer(params: { smsToken: string }) {
    return request(`${this.service}/customer?${stringify(params)}`);
  }
  // 统计要发的短信数量
  count(stationId: string,
    planId: string,
    smsTemplateId: string,
    sign: string,
    schoolId?: string,
    grade?: string,
    clazz?: string,
    params?: string[],
    size?: number,
    page?: number,
    keyword?: string) {
    return request(`${this.service}/count?stationId=${stationId ?? ''}&planId=${planId ?? ''}&smsTemplateId=${smsTemplateId ?? ''}&schoolId=${schoolId ?? ''}&grade=${grade ?? ''}&clazz=${clazz ?? ''}${keyword ? `&keyword=${keyword}` : ''}&size=${size ?? ''}&page=${page ?? ''}&sign=${sign}`, {
      body: JSON.stringify(params ?? []),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }
  // 勾选发送短信 
  chooseSend(
    params: {stationId: string,
    smsTemplateId: string,
    timerTime?: string,
    ids?: string[]}
  ) {
    // ?${stringify({ stationId: stationId, smsTemplateId: smsTemplateId, timerTime: timerTime, ids: ids })}
    return request(`${this.service}/choose-send`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }
}

const groupService = new GroupService('/admin/sms');

export default groupService;
