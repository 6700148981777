import commonStore from '../common.store';
import RESTService from '../rest.service';
import { School } from './school.interface';

const expand = ['user'].join(',');

export class SchoolService extends RESTService<School> {
  list(params?: { [key: string]: any }) {
    return super.list({ expand, ...commonStore.userAssociatedInfo, ...params });
  }
  get(id: string, params?: { [key: string]: any }) {
    return super.get(id, { expand, ...params });
  }
}

const schoolService = new SchoolService('/admin/school');

export default schoolService;
