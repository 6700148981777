import { action, computed, observable, set } from 'mobx';
import { task } from 'mobx-task';
import { normalize, schema } from 'normalizr';
import { stringify } from 'qs';
import { PaginationMeta } from '../common.interface';
import { defaultPagination } from '../config';
import { ReportSchool } from '../report/report.interface';
import reportService from './escalation_record.service';

export const reportSchema = new schema.Entity('escalation');

export class EscalationStore {
  _count = 0;
  @observable query?: Parameters<typeof reportService.list>[0];
  @observable entities: { [key: string]: ReportSchool } = {};
  @observable ids: { [qs: string]: number[] } = {};
  @observable pgs: { [qs: string]: { [key: string]: number } } = {};

  @computed get dataSource() {
    return (this.ids[stringify(this.query, { sort: (a, b) => a.localeCompare(b) })] || []).map(
      (id) => this.entities[id],
    );
  }

  @computed get pagination() {
    const pagination = this.pgs[stringify(this.query, { sort: (a, b) => a.localeCompare(b) })];
    return (
      !!pagination && {
        current: pagination.page + 1,
        pageSize: pagination.size,
        total: pagination.total,
        ...defaultPagination,
      }
    );
  }

  getDataSource(query: { [key: string]: any }) {
    return (this.ids[stringify(query, { sort: (a, b) => a.localeCompare(b) })] || []).map((id) => this.entities[id]);
  }

  getPagination(query: { [key: string]: any }) {
    const pagination = this.pgs[stringify(query, { sort: (a, b) => a.localeCompare(b) })];
    return (
      !!pagination && {
        current: pagination.page + 1,
        pageSize: pagination.size,
        total: pagination.total,
        ...defaultPagination,
      }
    );
  }

  @action updateEntities(entities: { [key: string]: ReportSchool } = {}) {
    const ids = Object.keys(entities);
    for (const id of ids) {
      this.entities = { ...this.entities, [id]: { ...this.entities[id], ...entities[id] } }; // mobx4
      // this.entities[id] = { ...this.entities[id], ...entities[id] }; // mobx5
    }
  }

  @action relist(query: Partial<Parameters<typeof reportService.list>[0]>, reload = false) {
    return this.list({ ...this.query, ...query } as any, reload);
  }

  @task.resolved @action async list(query: Parameters<typeof reportService.list>[0], reload = false) {
    const _count = ++this._count;
    this.query = { ...query };
    const qs = stringify(this.query, { sort: (a, b) => a.localeCompare(b) });

    // 如果列表已加载且不刷新，则使用缓存
    if (this.ids[qs] && !reload) {
      return this.ids[qs].map((id) => this.entities[id]);
    }

    const listMethod = reportService.list.bind(reportService);

    const data = await listMethod(this.query);
    const { items, meta } = data as {
      items: ReportSchool[];
      meta: PaginationMeta;
    };

    const _items = (items ?? data)
      .filter((item) => !!item)
      .map((item) => {
        item.id = [
          item.planId,
          item.province,
          item.city,
          item.county,
          item.stationId,
          item.schoolCategory,
          item.schoolId,
          item.grade,
          item.clazz,
          item.age,
        ]
          .filter((key) => !!key)
          .join('_');
        return item;
      });

    const { result, entities } = normalize(_items, [reportSchema]);
    this.updateEntities(entities.escalation);

    if (_count === this._count) {
      set(this, {
        ids: reload ? { [qs]: result } : { ...this.ids, [qs]: result },
        pgs: reload ? { [qs]: meta } : { ...this.pgs, [qs]: meta },
      });
    }

    return data;
  }

  @task.resolved @action async get(...args: Parameters<typeof reportService.get>) {
    const getMethod = reportService.get.bind(reportService);

    const data = await getMethod(...args);
    if (data) {
      data.id = [
        data.planId,
        data.province,
        data.city,
        data.county,
        data.stationId,
        data.schoolCategory,
        data.schoolId,
        data.grade,
        data.clazz,
        data.age,
      ]
        .filter((key) => !!key)
        .join('_');

      const { entities } = normalize(data, reportSchema);
      this.updateEntities(entities.escalation);
    }

    return data;
  }
}

const escalationStore = new EscalationStore();

export default escalationStore;
